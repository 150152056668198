<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import {
	goalMethods,
  interactionMethods,
  propertyMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Goals",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Multiselect
  },
  data() {
	return {
		title: "Custom Goals",
		goals: [],
    currentPage:1,
		perPage:8,
		rows:0,
    isLoading:false,
    pageLoaded:false,
    empty_config:{
      "title" : this.$t('goals.empty'),
        "subtitle" : this.$t('goals.empty_subtitle'),
        "buttonText" : this.$t('goals.new'),
        icon: "fa-cubes"
      },
      empty_list_config:{
          "title" :this.$t('goals.empty_search_title'),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      query:'',
      goal: {
        name: '',
        filter:{
          value:''
        },
        type: null
      },
      showNewGoal:false,
      isGoalKeyNotDuplicated:false,
      goal_types: [
        {id:'page_view', label:this.$t('goals.type_page_view')},
        {id:'click', label:this.$t('goals.type_click') },
        {id:'interaction', label:this.$t('goals.type_interaction')}
      ],
      submitted:false,
      confirmDelete:false,
      page_conditions: [
        {id: 'like', label:this.$t('goals.condition_type_contains')},
        {id: 'not_like', label:this.$t('goals.condition_type_not_contains')}
      ],
      auxPageViewCondition: {id: 'like', label:this.$t('goals.condition_type_contains')},
      interactions: [
        {key: 'purchase', name:this.$t('goals.purchase'), parameters:[{type: 'item'}]},
        {key: 'cart', name:this.$t('goals.cart'), parameters:[{type: 'item'}]},
        {key: 'view', name:this.$t('goals.view'), parameters:[{type: 'item'}]},
        {key: 'like', name:this.$t('goals.like'), parameters:[{type: 'item'}]},
        {key: 'notification-opened', name:this.$t('goals.notification-opened')},
      ],
      auxInteraction: null,
      isValueValid:true,
      isRevenueValid: true,
      parameters: [],
      properties_products: []
    }
  },
  validations: {
      goal: {
          name: { required },
          type: { required },
      },
  },
  mounted() {
    this.loadCustomGoals(true);
    this.loadCustomInteractions();
    this.loadProperties();
  },
  computed : {
    isFilterValid(){
      return this.validateGoalFilter();
    },
    filter() {
      return this.goal.filter.value;
    }
  },
  created(){
		this.debounceCustomGoals = _debounce(this.searchCustomGoals.bind(this), 1000);
	},
  methods: {
	...goalMethods,
  ...interactionMethods,
  ...propertyMethods,
  
  addFixedProperties(){
      this.properties_products.unshift({
        type : 'number',
        name: 'Price',
        column_name: 'price'
      });
  },
  loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        
        this.properties_products = properties.filter(p=> p.entity == 'product');
        this.addFixedProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

  onNewCustomGoal(){
    this.goal = {
      name: '',
      filter:{
        value:''
      }
    }
    this.submitted = false;
    this.showNewGoal = true;
  },
  loadCustomInteractions(){
    const params = {
      q : `where[project]=${localStorage.getItem('current_project')}&limit=50`
    }
    this.getInteractions(params).then((res)=>{
      if(res.data){
        this.interactions = this.interactions.concat(res.data);  
      }
    })
  },

  loadCustomGoals(){

    let loader = this.$loading.show();
    const params={
      q: `project=${localStorage.getItem('current_project')}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
    }

    this.isLoading = true;
    this.getGoals(params).then((res)=>{
        this.goals = res.data;
        this.rows= res.totalCount;
        this.pageLoaded = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('goals.get_goals_error'),title: this.$t('goals.title') });		
      }).finally(()=>{
        this.isLoading = false
        loader.hide();
      })

      loader.hide();
  },

  onCustomGoalsListPageClicked(){
    this.loadCustomGoals();
  },

  onEditCustomGoalClicked(data){
    this.goal = Object.assign({}, data);
    this.onInteractionChanged();
    this.submitted = false;
    
    this.showNewGoal = true;
  },

  searchCustomGoals(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t('goals.empty_search_text').replaceAll('#', this.query);

			if(query){
				this.currentPage = 1;
					const params={
							q: `project=${localStorage.getItem('current_project')}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getGoals(params).then((res)=>{
							this.goals = res.data;
              this.rows= res.totalCount;
            }).catch(()=>{
							this.$notify({ type: 'error', text: this.$t('goals.get_goals_error'),title: this.$t('goals.title') });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
            
			}else{
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadCustomGoals();
			}
		},

    onResetSearchClicked(){
      this.query = '';
      this.loadCustomGoals();
    },
    
    onConfirmNewGoalClicked(){
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
				return;
			}

      if(!this.validateGoalFilter())
        return;

      this.createOrUpdateCustomGoal()
    },
    onRemoveCustomGoal(data){
      this.goal = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveGoalClicked(){
      let loader = this.$loading.show();

      this.deleteGoal(this.goal._id).then(()=>{
        this.currentPage=1;
        this.loadCustomGoals();
        this.$notify({ type: 'success', text: this.$t('goals.delete_goal_success'),title:  this.$t('goals.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('goals.delete_goal_error'),title:  this.$t('goals.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    createOrUpdateCustomGoal(){
      const loader = this.$loading.show();
      this.goal.project = localStorage.getItem('current_project');

      if(!this.goal.conversion)
        this.goal.conversion = 0;

      if(!this.goal._id){
        this.createGoal(this.goal).then(()=>{
          this.$notify({ type: 'success', text: this.$t('goals.create_goal_success'),title: this.$t('goals.title') });
          this.showNewGoal = false;
          this.currentPage = 1;
          this.loadCustomGoals();
        }).catch((ex)=>{
          // eslint-disable-next-line no-console
          console.log(ex);
          this.$notify({ type: 'error', text: this.$t('goals.create_goal_error'),title: this.$t('goals.title') });
        }).finally(()=>{
          loader.hide()
        })
      }else{
        this.updateGoal(this.goal).then(()=>{
          this.$notify({ type: 'success', text: this.$t('goals.update_goal_success'),title: this.$t('goals.title') });
          this.showNewGoal = false;
          this.isGoalKeyNotDuplicated = true
          this.currentPage = 1;
          this.loadCustomGoals();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('goals.update_goal_error'),title: this.$t('goals.title') });
        }).finally(()=>{
          loader.hide()
        })
      }
    },
    onTypeSelected(){
      
      if(this.goal.type.id == 'page_view'){
        this.goal.filter = {
          operator: {id: 'like', label:this.$t('goals.condition_type_contains')},
          value: '',
          conversion: 0
        }
      }else if(this.goal.type.id== 'click'){
        this.goal.filter = {
          operator:{id: '=', label:'Igual'},
          value: '',
          conversion: 0
        }
      }else{
        this.goal.filter = {
          operator:{id: '=', label:'Igual'},
          value: '',
          interaction: {key: 'purchase', name:'Purchase'},
          conversion: null
        }
      } 
      this.submitted = false;
      this.isValueValid = true
    },

    validateGoalFilter(){
      if(this.goal.type?.id == 'page_view' || this.goal.type?.id== 'click'){
        this.isValueValid = this.goal.filter.value !=null &&  this.goal.filter.value!=''
      }else{
        this.isValueValid = true;
      }
      
      return this.isValueValid;
    },
    onInteractionChanged(){
      this.parameters = [];
      
      if(this.goal.filter?.interaction){
        if(this.goal.filter?.interaction?.parameters?.filter(param=>param.type =='item').length > 0){
          this.properties_products.filter(pr=>pr.type=='number').forEach(pp=>{
            this.parameters.push({
              id: `_product_${pp.column_name}`
            })
          })
        }
        if(this.goal.filter?.interaction?.parameters){
          this.parameters = this.parameters.concat(this.goal.filter?.interaction?.parameters?.filter(p=>p.type=='number'));
        }
      }else{
        this.parameters = []
      }
    }
  },
  watch:{
    filter() {
      this.submitted = false
    },
    auxInteraction: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.parameters = newVal.parameters.filter(p=>p.type=='number');
        }else{
          this.parameters = []
        }
      }
    }
  }
  
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('goals.title')" :items="[]" />
		<div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">  
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="search-box me-2 mb-0 d-inline-block">
                        <div class="position-relative">
                            <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.search')"
                            @input="debounceCustomGoals($event.target.value)"
                            v-model="query"
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="text-sm-end">
                          <button
                            type="button"
                            class="btn btn-primary mb-0 me-0" @click="onNewCustomGoal"
                            v-if="goals.length>0 || query!=''">
                              <i class="mdi mdi-plus me-1"></i> {{$t('goals.new')}}
                          </button>
                        </div>
                    </div>
                </div>
                <EmptyList :config="empty_list_config" v-if="!isLoading && goals.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if=" !isLoading && goals.length == 0 && query == ''" @onButtonClicked="onNewCustomGoal" class="mt-3"/>
            </div>
            <div class="table-responsive mb-0" v-if="pageLoaded && goals.length > 0">
              <table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">{{$t('goals.name')}}</th>
                    <th scope="col">{{$t('goals.type')}}</th>
                    <th scope="col" class="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="goal in goals" :key="goal._id">
                    <td>
                      <h5 class="font-size-14 mb-1" v-on:click="onEditCustomGoalClicked(goal)">
                        <a href="#" class="text-dark">{{goal.name}}</a>
                      </h5>
                    </td>
                    <td>
                      {{ $t(`goals.type_${goal.type.id}`)}}
                    </td>
                    <td class="text-end">
                      <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditCustomGoalClicked(goal)" ></i>
                      <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveCustomGoal(goal)" ></i>
                    </td>
                </tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="row mb-3" v-if="rows > perPage">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      @input="onCustomGoalsListPageClicked"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
      </div>
      <b-modal v-model="showNewGoal" id="modal-center" :title="goal._id ? goal.name : $t('goals.new')" title-class="font-18" @ok.prevent="onConfirmNewGoalClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <div class="row">
            <div class="mb-3">
                <label for="title">{{$t('goals.name')}}*</label>
                <input
                    id="title"
                    v-model="goal.name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('goals.name_placeholder')"
                    :class="{ 'is-invalid': submitted && $v.goal.name.$error }"
                />
                <div v-if="submitted && !$v.goal.name.required" class="invalid-feedback">
                    {{$t('goals.name_required')}}
                </div>
            </div>
            <div class="mb-3">
                <label for="title">{{$t('goals.type')}}*</label>
                <multiselect 
                    v-model="goal.type"
                    :options="goal_types"
                    :selectLabel="$t('common.select')"
                    :deselectLabel="$t('common.deselect')"
                    :placeholder="$t('goals.type_placeholder')"
                    :multiple="false"
                    track-by="id" 
                    label="label"
                    :show-labels="false"
                    :showNoResults="false"
                    :class="{ 'is-invalid': submitted && $v.goal.type.$error }"
                    @input="onTypeSelected">
                </multiselect>
                <div v-if="submitted && !$v.goal.type.required" class="invalid-feedback">
                    {{$t('goals.type_required')}}
                </div>
            </div>
            <div class="mb-3" v-if="goal.type?.id=='page_view'">
                <div class="row">
                  <div class="col">
                    <label for="title">{{$t('goals.condition')}}*</label>
                    <multiselect 
                      v-model="goal.filter.operator"
                      :options="page_conditions"
                      :multiple="false"
                      track-by="id" 
                      label="label"
                      :show-labels="false"
                      :showNoResults="false">
                    </multiselect>
                  </div>
                  <div class="col">
                    <label for="title">URL*</label>
                    <input
                      id="title"
                      v-model="goal.filter.value"
                      type="text"
                      class="form-control"
                      :placeholder="$t('goals.url_placeholder')"
                      :class="{ 'is-invalid': submitted && !isFilterValid }"
                    />
                    <div v-if="submitted && !isFilterValid" class="invalid-feedback" style="display: block;">
                      {{$t('goals.url_required')}}
                    </div>
                  </div>
                </div>
            </div>
            <div class="mb-3" v-if="goal.type?.id=='click'">
                <div class="row">
                  <div class="col">
                    <label for="title">{{$t('goals.selector')}}*</label>
                    <input
                      v-model="goal.filter.value"
                      type="text"
                      class="form-control"
                      :placeholder="$t('goals.selector_placeholder')"
                      :class="{ 'is-invalid': submitted && !isFilterValid }"
                    />
                    <div v-if="submitted && !isFilterValid" class="invalid-feedback" style="display: block;">
                        {{$t('goals.selector_required')}}
                    </div>
                  </div>
                </div>
            </div>
            <div class="mb-3" v-if="goal.type?.id=='interaction'">
                <div class="row">
                  <div class="col">
                    <label for="title">{{$t('goals.interaction')}}*</label>
                    <multiselect 
                      v-model="goal.filter.interaction"
                      :options="interactions"
                      :multiple="false"
                      track-by="key" 
                      label="name"
                      :placeholder="$t('goals.interaction_placeholder')"
                      :show-labels="false"
                      :allowEmpty="false"
                      :showNoResults="false"
                      @input="onInteractionChanged">
                    </multiselect>
                  </div>
                </div>
            </div>
            <div class="mb-3" v-if="goal.type">
                <div class="row">
                  <div class="col">
                    <label for="title">Revenue*</label>
                    <input class="form-control" placeholder="Enter the revenue for this goal" v-if="goal.type?.id!= 'interaction'" v-model="goal.filter.conversion" type="number" />
                    <multiselect 
                      v-model="goal.filter.conversion"
                      :options="parameters"
                      :multiple="false"
                      track-by="id" 
                      label="id"
                      placeholder="Select the parameter"
                      :show-labels="false"
                      :showNoResults="false"
                      v-if="goal.type?.id== 'interaction'">
                    </multiselect>
                  </div>
                </div>
            </div>
          </div>
      </b-modal>
      <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveGoalClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <p>{{$t('goals.remove_goal')}}</p>
      </b-modal>
    </div>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}
</style>